<template>
  <div>
    <div class="sm:flex sm:items-center sm:justify-between mb-20">
      <h1 class="font-medium text-lg mb-12 sm:mb-0">Accounting</h1>

      <div class="flex">
        <el-button
          tag="button"
          :disabled="!adminOrders.length"
          class="group mr-16"
          @click="onDownloadFile('csv')"
        >
          <template #icon>
            <span class="icon-download text-grey-500 group-hover:text-grey-400 text-xl block" />
          </template>
          Download csv
        </el-button>

        <el-button
          tag="button"
          type="primary"
          :loading="csvUploadLoading"
          @click="onUploadFile"
        >
          Upload csv
        </el-button>
        <input ref="inputFile" type="file" hidden accept=".csv" @change="onInputFileChange">
      </div>
    </div>

    <admin-table :items="adminOrders" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import AdminService from '@/services/admin.service'

const AdminTable = () => import('@/components/finance/AdminTable')
const ElButton = () => import('@/components/shared/ELButton')

export default {
  name: 'DashboardPage',

  components: { AdminTable, ElButton },

  data () {
    return {
      adminOrders: [],
      csvUploadLoading: false
    }
  },

  created () {
    const testing = {}
    const test = testing[2]
    console.log(test, testing[2])
    this.firstLoad()
  },

  methods: {
    ...mapActions(['loading', 'showNotification']),

    firstLoad () {
      AdminService.getFinanceOrders()
        .then(res => {
          this.adminOrders = res
          this.loading(false)
        })
    },

    onDownloadFile (fileType) {
      AdminService.downloadOrdersFile({ format: fileType })
        .then(res => {
          const fileURL = window.URL.createObjectURL(new Blob([res]))
          let fileFormat = ''

          if (fileType === 'csv') fileFormat = '.csv'

          const a = document.createElement('a')
          a.href = fileURL
          a.download = `orders${fileFormat}`
          a.click()
        })
        .catch(() => {
          this.showNotification({ type: 'error', message: 'Something went wrong. Please, try again later.' })
        })
    },

    onUploadFile () {
      this.$refs.inputFile.click()
    },

    onInputFileChange () {
      const file = this.$refs.inputFile.files[0]
      if (file && file.type.includes('csv')) {
        const formData = new FormData()
        formData.set('file', file)
        this.csvUploadLoading = true
        AdminService.uploadOrdersFile(formData)
          .then(res => {
            this.csvUploadLoading = false
            this.showNotification({ type: 'success', message: 'File was successfully uploaded.' })
          })
          .catch(() => {
            this.csvUploadLoading = false
          })
      } else {
        this.showNotification({ type: 'error', message: 'Only CSV files allowed.' })
        this.$refs.inputFile.files = null
      }
    }
  }
}
</script>
